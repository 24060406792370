import React, { useState } from 'react';
import { List, Datagrid, TextField, useRecordContext, useNotify, fetchUtils, TopToolbar, TextInput, Form, useRefresh  } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./style.css";


const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  


const QuestionAnswerList = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [openAddQuestionAnswer, setOpenAddQuestionAnswer] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [newAnswer, setNewAnswer] = useState('');

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteQA, setDeleteQA] = useState(null);

    const [updateQA, setUpdateQA] = useState(null);
    const [openUpdateQA, setOpenUpdateQA] = useState(false);

    const handleAddOpen = (e) => {
        setOpenAddQuestionAnswer(true);
    };

    const handleAddClose = () => {
        setOpenAddQuestionAnswer(false);
    };

    const handleAddConfirm = async () => {  
        if (newQuestion && newAnswer) {  
            const add_question_answer_url = `${apiUrl}/add_question_answer`;  
    
            // Prepare the data you want to send  
            const data = {  
                question: newQuestion,
                answer: newAnswer
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(add_question_answer_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Added New Question and Answer`, { type: 'info' });  
                    setNewQuestion('');  
                    setNewAnswer(''); 
                    handleAddClose();  
                } else {  
                    notify(`Failed to add question and answer.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                notify(`Error adding question and answer.`, {type: 'warning'}); 
            }  
        } else {  
            notify(`Please input question and answer.`, {type: 'warning'});  
        }  
    };

    const UpdateButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setUpdateQA(record);
            handleUpdateOpen(true);
        }
        return <button onClick={handleClick}>Update</button>;
    };

    const handleUpdateOpen = (e) => {
        setOpenUpdateQA(true);
    };

    const handleUpdateClose = () => {
        setOpenUpdateQA(false);
    };

    const handleUpdateConfirm = async () => {  
        if (newQuestion && newAnswer) {  
            const add_question_answer_url = `${apiUrl}/update_question_answer`;  
    
            // Prepare the data you want to send  
            const data = {  
                question: newQuestion,
                answer: newAnswer,
                qa_id: updateQA._id
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(add_question_answer_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Updated Question and Answer`, { type: 'info' });  
                    setNewQuestion('');  
                    setNewAnswer(''); 
                    handleUpdateClose();  
                } else {  
                    notify(`Failed to update question and answer.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                console.error('Error updating question and answer:', error);  
                notify(`Error updating question and answer.`, {type: 'warning'});  
            }  
        } else {  
            notify(`Please input question and answer.`, {type: 'warning'});  
        }  
    };

    const DeleteButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setDeleteQA(record);
            setDeleteOpen(true);
        }
        return <button onClick={handleClick}>Delete</button>;
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteQA(null);
    };

    const handleDeleteConfirm = async () => {
        if (deleteQA) {
            await question_answer_delete(deleteQA);
            handleDeleteClose();
        }
    };

    const question_answer_delete = async (deleteQA) => {
        try {
            const delete_url = `${apiUrl}/delete_question_answer/${deleteQA._id}`;
            await httpClient(delete_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Delete Question and Answer`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error delete Question and Answer`, {type: 'warning'});
        }
    };


    return (
        <>
            <TopToolbar>
                <Button onClick={(e) => handleAddOpen(e)}>Add New</Button>
            </TopToolbar>
            <List title='Question and Answers' {...props}>
                <Datagrid rowClick="edit">
                    <TextField source='_id' />
                    <TextField source='question' />
                    <TextField source='answer' />
                    <UpdateButton />
                    <DeleteButton />
                </Datagrid>
            </List>

            {/* Update question and answer */}
            <Dialog open={openAddQuestionAnswer} onClose={handleAddClose}>
                <DialogTitle>Add New question and answer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input question and answer
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>
                        <TextInput   
                            source="question"   
                            value={newQuestion}   
                            onChange={(e) => setNewQuestion(e.target?.value)}   
                            resettable   
                        />   
                        <TextInput   
                            source="answer"   
                            value={newAnswer}   
                            onChange={(e) => setNewAnswer(e.target?.value)}  
                            resettable   
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddConfirm} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add new question and answer */}
            <Dialog open={openUpdateQA} onClose={handleUpdateClose}>
                <DialogTitle>Update question and answer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input question and answer
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>
                        <TextInput   
                            source="question"   
                            value={newQuestion}   
                            onChange={(e) => setNewQuestion(e.target?.value)}   
                            resettable   
                        />   
                        <TextInput   
                            source="answer"   
                            value={newAnswer}   
                            onChange={(e) => setNewAnswer(e.target?.value)}  
                            resettable   
                        />
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateConfirm} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm delete question and answer */}
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this question and answer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default QuestionAnswerList;
