import React, { useState } from 'react';
import { List, Datagrid, useRecordContext, TextField, useNotify, fetchUtils, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./style.css";

// import FileEditor from './FileEditor';

const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  


const CustomerList = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteCustomer, setDeleteCustomer] = useState(null);

 
    const DeleteButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setDeleteCustomer(record);
            setDeleteOpen(true);
        }
        return <button onClick={handleClick}>Delete</button>;
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteCustomer(null);
    };

    const handleDeleteConfirm = async () => {
        if (deleteCustomer) {
            await customer_delete(deleteCustomer);
            handleDeleteClose();
        }
    };

    const customer_delete = async (deleteCustomer) => {
        try {
            const delete_url = `${apiUrl}/delete/${deleteCustomer._id}`;
            await httpClient(delete_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Delete Customer ${deleteCustomer.name}`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error delete ${deleteCustomer.name}`, {type: 'warning'});
        }
    };

    return (
        <>       
            <List component="div" title='Customers' {...props}>
                <Datagrid rowClick="edit">
                    <TextField source='_id' />
                    <TextField source='bot_type' />
                    <TextField source='name' />
                    <TextField source='email' />
                    <TextField source='country' />
                    <TextField source='business' />
                    <TextField source='company' />
                    <DeleteButton />
                </Datagrid>
            </List>
      
            {/* Confirm delete customer */}
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this customer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
    
};

export default CustomerList;
