import React, { useState } from 'react';
import { List, Datagrid, TextField, useRecordContext, SelectInput, useNotify, fetchUtils, TopToolbar, TextInput, Form, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./style.css";


const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  

const BotList = (props) => {

    const notify = useNotify();
    const refresh = useRefresh();
    const [openAddBot, setOpenAddBot] = useState(false);
    const [newToken, setNewToken] = useState('');
    const [newType, setNewType] = useState('');
    const [newother, setNewOther] = useState('')
    const [newaccountsid, setNewAccountSid] = useState('') 

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteBot, setDeleteBot] = useState(null);

    const [updateBot, setUpdateBot] = useState(null);
    const [openUpdateBot, setOpenUpdateBot] = useState(false);

    const handleAddOpen = (e) => {
        setOpenAddBot(true);
    };

    const handleAddClose = () => {
        setOpenAddBot(false);
    };

    const addNewData = async() => {
        const add_Bot_url = `${apiUrl}/add_bot`;  
    
        // Prepare the data you want to send  
        const data = {
            bot_id: "",  
            token: newToken,
            bot_type: newType,
            other: newother,
            account_sid: newaccountsid
        };  

        try {  
            // Send POST request  
            const { status } = await httpClient(add_Bot_url, {  
                method: 'POST',
                body: JSON.stringify(data),
            });  

            if (status >= 200 && status < 300) {  
                notify(`Added New Bot`, { type: 'info' });  
                setNewToken('');
                setNewAccountSid('')
                setNewOther('')
                setNewType('') 
                handleAddClose();  
            } else {  
                notify(`Failed to add new bot.`, 'error');  
            } 
            refresh(); 
        } catch (error) {  
            console.error('Error adding new bot:', error);  
            notify(`An error occurred: ${error.message}`, 'error');  
        }  
    }
    const handleAddConfirm = async () => {  
        if (newType) {  
            if (newType === 'telegram') {  
                if (newToken) {  
                    await addNewData();
                } else {  
                    notify("Token is required for telegram.", {type: 'warning'});  
                }  
            } else if (newType === 'livechat') {  
                if (newToken && newother) {  
                    await addNewData();
                } else {  
                    notify("Token and email of your livechat profile are required for livechat.", {type: 'warning'});  
                }  
            } else if (newType === 'whatsapp') {  
                if (newother && newToken && newaccountsid) {  
                    await addNewData();
                } else {  
                    notify("Twilio phone number, account sid and token are required for whatsapp.", {type: 'warning'});  
                }  
            } else {  
                notify("Invalid Bot type.", {type: 'warning'});  
            }  
        } else {  
            notify("Bot type is required.", {type: 'warning'});  
        }  

    };

    const UpdateButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setUpdateBot(record);
            setNewToken('aa');
            handleUpdateOpen(true);
        }
        return <button onClick={handleClick}>Update</button>;
    };

    const handleUpdateOpen = (e) => {
        setOpenUpdateBot(true);
    };

    const handleUpdateClose = () => {
        setOpenUpdateBot(false);
        setNewToken('');
    };

    const handleUpdateConfirm = async () => {  
        if (newToken) {  
            const Update_Bot_url = `${apiUrl}/update_bot`;  

            // Prepare the data you want to send  
            const data = {  
                bot_id: updateBot._id,  
                token: newToken,
                bot_type: newType,
                other: newother,
                account_sid: newaccountsid
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(Update_Bot_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Updated Bot`, { type: 'info' });  
                    setNewToken('');
                    setNewAccountSid('')
                    setNewOther('')
                    setNewType('')
                    handleUpdateClose();  
                } else {  
                    notify(`Failed to Update Bot.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                console.error('Error updateing Bot:', error);  
                notify(`An error occurred: ${error.message}`, 'error');  
            }  
        } else {  
            notify(`Please input Bot.`, {type: 'warning'});  
        }  
    };

    const DeleteButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setDeleteBot(record);
            setDeleteOpen(true);
        }
        return <button onClick={handleClick}>Delete</button>;
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteBot(null);
    };

    const handleDeleteConfirm = async () => {
        if (deleteBot) {
            await Bot_delete(deleteBot);
            handleDeleteClose();
        }
    };

    const Bot_delete = async (deleteBot) => {
        try {
            const delete_url = `${apiUrl}/delete_bot/${deleteBot._id}`;
            await httpClient(delete_url);
            // notify(`Calling ${phone_number}`, 'info');
            notify(`Delete seleted Bot`, {type: 'info'});
            refresh();
        } catch (e) {
            console.error(e);
            notify(`Error delete seleted bot`, {type: 'warning'});
        }
    };

    return (
        <>
            <TopToolbar>
                <Button onClick={(e) => handleAddOpen(e)}>Add New</Button>
            </TopToolbar>
            <List title='Bots' {...props}>
                <Datagrid rowClick="edit">
                    <TextField source='_id' />
                    <TextField source='bot_type' />
                    <TextField source='token' />
                    <TextField source='other' />
                    <UpdateButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        
              {/* Add new question and answer */}
              <Dialog open={openAddBot} onClose={handleAddClose}>
                <DialogTitle>Add New Bot</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input Bot
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>  
                        <SelectInput   
                            source="bot_type"   
                            value={newType}   
                            onChange={(e) => {  
                                setNewType(e.target.value);  
                                // Reset the operator email when changing bot type if needed  
                                if (e.target.value !== 'livechat') {  
                                    setNewOther(''); // This resets the email if not using livechat  
                                }
                                if (e.target.value !== 'whatsapp') {  
                                    setNewOther(''); // This resets the email if not using livechat 
                                    setNewAccountSid('') 
                                }  
                            }}  
                            choices={[  
                                { id: 'telegram', name: 'Telegram' },  
                                { id: 'livechat', name: 'LiveChat' },  
                                { id: 'whatsapp', name: 'Whatsapp' },  
                            ]}  
                            resettable   
                        />  

                        {/* Conditionally render the token input field based on bot_type */}  
                        <TextInput   
                            source='token'   
                            value={newToken}   
                            onChange={(e) => setNewToken(e.target?.value)}   
                            resettable   
                            multiline   
                        />  

                        {/* Conditionally render the site_email field only when 'livechat' is selected */}  
                        {newType === 'livechat' && (  
                            <TextInput   
                                source="site profile email"   
                                value={newother}   
                                onChange={(e) => setNewOther(e.target?.value)}   
                                resettable   
                                multiline   
                            />  
                        )}
                        {/* Display the 'other' input when 'whatsapp' is selected */}  
                        {newType === 'whatsapp' && ( 
                            <>
                                <TextInput   
                                    source="phone"   
                                    value={newother}  // Reusing newSiteEmail for the 'other' field  
                                    onChange={(e) => setNewOther(e.target?.value)}   
                                    resettable   
                                    multiline   
                                /> 
                                <TextInput   
                                    source="twilio account sid"   
                                    value={newaccountsid}  // Reusing newSiteEmail for the 'other' field  
                                    onChange={(e) => setNewAccountSid(e.target?.value)}   
                                    resettable   
                                    multiline   
                                />  
                            </> 
                        )}     
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddConfirm} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm delete Bot */}
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Bot?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Update Bot */}
            <Dialog open={openUpdateBot} onClose={handleUpdateClose}>
                <DialogTitle>Update Bot</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input Bot
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>  
                        <SelectInput   
                            source="bot_type"   
                            value={newType}   
                            onChange={(e) => {  
                                setNewType(e.target.value);  
                            }}  
                            choices={[  
                                { id: 'telegram', name: 'Telegram' },  
                                { id: 'livechat', name: 'LiveChat' },  
                                { id: 'whatsapp', name: 'Whatsapp' },  
                            ]}  
                            resettable   
                        />  

                        {/* Conditionally render the token input field based on bot_type */}  
                        <TextInput   
                            source='token'   
                            value={newToken}   
                            onChange={(e) => setNewToken(e.target?.value)}   
                            resettable   
                            multiline   
                        />  

                        {/* Conditionally render the site_email field only when 'livechat' is selected */}  
                        {newType === 'livechat' && (  
                            <TextInput   
                                source="site profile email"   
                                value={newother}   
                                onChange={(e) => setNewOther(e.target?.value)}   
                                resettable   
                                multiline   
                            />  
                        )}
                        {/* Display the 'other' input when 'whatsapp' is selected */}  
                        {newType === 'whatsapp' && ( 
                            <>
                                <TextInput   
                                    source="phone"   
                                    value={newother}  // Reusing newSiteEmail for the 'other' field  
                                    onChange={(e) => setNewOther(e.target?.value)}   
                                    resettable   
                                    multiline   
                                /> 
                                <TextInput   
                                    source="twilio account sid"   
                                    value={newaccountsid}  // Reusing newSiteEmail for the 'other' field  
                                    onChange={(e) => setNewAccountSid(e.target?.value)}   
                                    resettable   
                                    multiline   
                                />  
                            </> 
                        )}     
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateConfirm} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default BotList;
