import React from 'react';
import { Admin, Resource } from 'react-admin';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import customDataProvider from './customDataProvider'; // Your custom data provider
import CustomerList from './components/CustomerList';
import ChatHistoryList from './components/ChatHistoryList';
import QuestionAnswerList from './components/QuestionAnswerList';
import PromptList from './components/PromptList';
import BotList from './components/BotList';
import Users from './components/Users';
import ChatSummaryList from './components/ChatSummaryList';
import "./components/style.css";
import authProvider from './authProvider';
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import { ThemeProvider, createTheme } from '@mui/material/styles'; 


// Create a custom theme  
const theme = createTheme({  
    palette: {  
        primary: {  
            main: '#1976d2', // Customize your primary color  
        },  
        secondary: {  
            main: '#dc004e', // Customize your secondary color  
        },  
    },  
});  

function App() {
    return (
        <ThemeProvider theme={theme}> {/* Wrap your app with ThemeProvider */}  
        <Router>  
            <Routes>  
                <Route path="/signup" element={<SignUpPage />} />  
                <Route   
                    path="/*"  
                    element={  
                        <Admin   
                            dataProvider={customDataProvider}   
                            authProvider={authProvider}   
                            loginPage={LoginPage}   
                        >  
                            <Resource name='users' list={Users} />  
                            <Resource name='customers' list={CustomerList} />  
                            <Resource name='chat_history' list={ChatHistoryList} />  
                            <Resource name='chat_summary' list={ChatSummaryList} /> 
                            <Resource name='question_answer' list={QuestionAnswerList} /> 
                            <Resource name="prompt" list={PromptList} />
                            <Resource name="bot" list={BotList} /> 
                        </Admin>  
                    }   
                />  
            </Routes>  
        </Router>  
    </ThemeProvider>   
    );
}

export default App;
