import { jwtDecode } from 'jwt-decode';	

const authProvider = {
    login: async ({ username, email, password }) => {
        const response = await fetch('https://chat.workerlab.ai/auth/login', {
            method: 'POST',
            body: JSON.stringify({ username, email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (response.ok) {
            const { token } = await response.json();
            localStorage.setItem('token', token); // Save the token here
            return;
        }
        throw new Error('Login failed'); // Handle unauthorized error
    },

    logout: () => {
        localStorage.removeItem('token'); // Clear the token on logout
        return Promise.resolve();
    },

    checkAuth: () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject(); // No token found
        }

        try {
            const decodedToken = jwtDecode(token); // Decode the token to get its payload
            // Get the current time in UTC (in seconds, since JWT expiration is in seconds)
            const currentUTC = new Date();
            const currentTimeUTC = Math.floor(currentUTC.getTime() / 1000); // In seconds

            const expTime = decodedToken.exp; // Expiration time from the token

            if (expTime < currentTimeUTC) {
                localStorage.removeItem('token');
                return Promise.reject(); // Reject if token expired
            }

            return Promise.resolve(); // If the token is valid
        } catch (error) {
            console.error('Error decoding token:', error);
            return Promise.reject(); // Reject if decoding fails
        }
    },
    
    

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token'); // Clear token on error
            return Promise.reject(); // Reject the promise to trigger logout
        }
        return Promise.resolve(); // No issue, resolve
    },

    getIdentity: async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                return { id: decodedToken.sub, fullName: decodedToken.name || 'Authenticated User' };
            } catch (error) {
                console.log("Error decoding token in getIdentity", error);
                return Promise.reject(); // If decoding fails, reject
            }
        }
        console.log("No token found in getIdentity");
        return Promise.reject(); // If no token, reject
    },
};

export default authProvider;
