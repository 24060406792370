// components/SignUpPage.js  
import React, { useState } from 'react';  
import { useNavigate } from 'react-router-dom';  
import Box from '@mui/material/Box';  
import TextField from '@mui/material/TextField';  
import Button from '@mui/material/Button';  
import Typography from '@mui/material/Typography';  

const SignUpPage = () => {   
    const navigate = useNavigate();  

    const [username, setUsername] = useState('');  
    const [email, setEmail] = useState(''); // New state for email  
    const [password, setPassword] = useState('');  
    const [error, setError] = useState(null);  

    const handleSignUp = async (event) => {  
        event.preventDefault();  
        setError(null); // Reset error   b

        try {  
            const response = await fetch('https://chat.workerlab.ai/auth/signup', { // Replace with your API endpoint  
                method: 'POST',  
                headers: {  
                    'Content-Type': 'application/json',  
                },  
                body: JSON.stringify({ username, email, password }), // Include email in the request body  
            });  

            if (!response.ok) {  
                const message = await response.text();  
                throw new Error(message || 'Failed to sign up');  
            }  

            alert('Sign-up successful, please log in!');  
            navigate('/login'); // Optionally redirect to login on success  
        } catch (err) {  
            setError(err.message);  
        }  
    };  

    return (  
        <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5 }} component="form" onSubmit={handleSignUp}>  
            <Typography variant="h4" component="h2" gutterBottom>  
                Sign Up  
            </Typography>  
            {error && <Typography color="error" variant="body2">{error}</Typography>}  
            <TextField  
                type="text"   
                label="Username"  
                value={username}   
                onChange={(e) => setUsername(e.target.value)}  
                required   
                fullWidth  
                margin="normal"  
            />  
            <TextField  
                type="email"  // Set type to "email"  
                label="Email"   // Provide label for email input  
                value={email}   // Bind email state  
                onChange={(e) => setEmail(e.target.value)}   // Update email state on change  
                required   
                fullWidth  
                margin="normal"  
            />  
            <TextField  
                type="password"   
                label="Password"  
                value={password}   
                onChange={(e) => setPassword(e.target.value)}  
                required   
                fullWidth  
                margin="normal"  
            />  
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>  
                Sign Up  
            </Button>  
            <Button onClick={() => navigate('/login')} variant="text" fullWidth sx={{ mt: 2 }}>  
                Log In  
            </Button>  
        </Box>  
    );  
};  

export default SignUpPage;
