import React, { useState }  from 'react';
import { List, Datagrid, TextField, useRecordContext, useNotify, fetchUtils, useRefresh  } from 'react-admin';
import "./style.css";

const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  


const PostPanel = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [newQuestion, setNewQuestion] = useState('');
    const [newAnswer, setNewAnswer] = useState('');

    const record = useRecordContext();
    const chatLines = record.chat_history.split('\n').map((line, index) => (  
        <span key={index}>  
            {line}  
            {index < record.chat_history.split('\n').length - 1 && <br />}  
        </span>  
    )); 
    
    const handleAddConfirm = async () => {  
        if (newQuestion && newAnswer) {  
            const add_question_answer_url = `${apiUrl}/add_question_answer`;  
    
            // Prepare the data you want to send  
            const data = {  
                question: newQuestion,
                answer: newAnswer
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(add_question_answer_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Added New Question and Answer`, { type: 'info' });  
                    setNewQuestion('');  
                    setNewAnswer(''); 
                } else {  
                    notify(`Failed to add question and answer.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                console.error('Error adding question and answer:', error);  
                notify(`An error occurred: ${error.message}`, 'error');  
            }  
        } else {  
            notify(`Please input question and answer.`, {type: 'warning'});  
        }  
    };


    return (
        <div>
            <div>
                {chatLines}
            </div> <br /> 
            <div style={{ textAlign: 'center'}}>  
                {/* Add new question and answer */}  
                <label style={{width: '50%'}}>  
                    Question:  
                    <input  
                        value={newQuestion}   
                        onChange={(e) => setNewQuestion(e.target.value)}   
                        resettable   
                    />  
                </label> <br /> <br />   
                <label style={{width: '50%'}}>  
                    Answer:  
                    <input   
                        value={newAnswer}   
                        onChange={(e) => setNewAnswer(e.target.value)}  
                        resettable   
                    />   
                </label> <br />  <br /> 
                <button onClick={handleAddConfirm} style={{ color: 'primary' }}>  
                    Add  
                </button>  
            </div>  
        </div>
    ); 
    // return (
    //     <div dangerouslySetInnerHTML={{ __html: record.chat_history }} />
    // );
};

const FullAnswerField = () => {
    const record = useRecordContext();
    if (!record || !record.chat_history) return null;

    if (record.chat_history.length > 150) {
        return <span>{record.chat_history.substring(0, 150) + '...'}</span>;
    }

    return <span>{record.chat_history}</span>;
};

const ChatHistoryList = (props) => {
    return (
        <List title='Chat Histories' {...props}>
            <Datagrid rowClick="edit" expand={<PostPanel />}>
                <TextField source='_id' />
                <TextField source='bot_type' />
                <TextField source='name' />
                <TextField source='email' />
                <FullAnswerField source='chat_history'/>
            </Datagrid>
        </List>
    );
};

// Optional: Add PropTypes for better type checking
ChatHistoryList.propTypes = {
    // Define any props here if needed
};

export default ChatHistoryList;
