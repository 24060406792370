// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTable-root .MuiTableCell-paddingCheckbox {  
         display: none;  
     }
.single-line {  
    /* white-space: nowrap; Prevents text from wrapping   */
    overflow: hidden;    /* Hides overflow content */  
    text-overflow: ellipsis; /* Adds ellipsis (...) for overflow text */  
    max-width: 450px; /* Set a max width according to your layout */ 
    max-height: 50px; 
} `, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;SACS,aAAa;KACjB;AACL;IACI,uDAAuD;IACvD,gBAAgB,KAAK,2BAA2B;IAChD,uBAAuB,EAAE,0CAA0C;IACnE,gBAAgB,EAAE,6CAA6C;IAC/D,gBAAgB;AACpB","sourcesContent":[".MuiTable-root .MuiTableCell-paddingCheckbox {  \r\n         display: none;  \r\n     }\r\n.single-line {  \r\n    /* white-space: nowrap; Prevents text from wrapping   */\r\n    overflow: hidden;    /* Hides overflow content */  \r\n    text-overflow: ellipsis; /* Adds ellipsis (...) for overflow text */  \r\n    max-width: 450px; /* Set a max width according to your layout */ \r\n    max-height: 50px; \r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
