// components/CustomLoginPage.js  
import React from 'react';  
import { Login, useLogin, useNotify } from 'react-admin';  
import { useNavigate } from 'react-router-dom';  
import Box from '@mui/material/Box';  
import TextField from '@mui/material/TextField';  
import Button from '@mui/material/Button';  
import Typography from '@mui/material/Typography';  

const LoginPage = () => {  
    const login = useLogin();  
    const notify = useNotify();  
    const navigate = useNavigate();  

    const handleSubmit = (event) => {  
        event.preventDefault();  
        const { email, password } = event.target.elements; // Change to use email  

        login({ 
            username: "", 
            email: email.value,  // Update the login function to accept email as username  
            password: password.value,  
        }).catch(error => {  
            notify('Login failed, please try again', { type: 'warning' });  
        });  
    };  

    return (  
        <Login>  
            <Box  
                component="form"  
                onSubmit={handleSubmit}  
                display="flex"  
                flexDirection="column"  
                alignItems="center"  
                sx={{ width: 300, margin: 'auto', padding: 3 }} // Center form and set width  
            >  
                <Typography variant="h4" component="h1" gutterBottom>  
                    Login  
                </Typography>  
                <TextField   
                    name="email"   // Change name to email  
                    label="Email"   // Update label to Email  
                    variant="outlined"   
                    required   
                    margin="normal"   
                />  
                <TextField   
                    name="password"   
                    type="password"   
                    label="Password"   
                    variant="outlined"   
                    required   
                    margin="normal"   
                />  
                <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>  
                    Login  
                </Button>  
                <Button   
                    onClick={() => navigate('/signup')}   
                    variant="text"   
                    sx={{ marginTop: 2 }}>  
                    Sign Up  
                </Button> {/* Link to Sign Up */}  
            </Box>  
        </Login>  
    );  
};  

export default LoginPage;