import React from 'react';
import he from 'he';  
import { List, Datagrid, TextField, useRecordContext } from 'react-admin';
// import "./style.css";

const PostPanel = () => {
    const record = useRecordContext();
    return (
        <div dangerouslySetInnerHTML={{ __html: he.decode(record.chat_summary) }} />
    );
};

const FullHistoryField = () => {
    const record = useRecordContext();
    // Check if the chat_summary exists before accessing its length  
    if (record.chat_summary && record.chat_summary.length > 150) {  
        return <span>{he.decode(record.chat_summary.substring(0, 150)) + '...'}</span>;  
    } else if (record.chat_summary) {  
        // If it is defined but less than or equal to 150 characters, show it fully  
        return <span>{he.decode(record.chat_summary)}</span>;  
    }  

    // If chat_summary is null or undefined, return an alternative JSX (e.g., empty or a placeholder)  
    return <span>No summary available</span>;  
}

const ChatSummaryList = (props) => {

    return (
        <List title='Chat Summaries' {...props}>
            <Datagrid rowClick="edit" expand={<PostPanel />}>
                <TextField source='_id' />
                <TextField source='bot_type' />
                <TextField source='name' />
                <TextField source='email' />
                <FullHistoryField source='chat_summary' />
            </Datagrid>
        </List>
    );
};

export default ChatSummaryList;
