import React, { useState } from 'react';
import { List, Datagrid, TextField, useRecordContext, useNotify, TextInput, SelectInput, Form, fetchUtils, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import "./style.css";

const apiUrl = 'https://chat.workerlab.ai';
const httpClient = (url, options = {}) => {  
    const token = localStorage.getItem('token');  

    if (token) {  
        options.headers = new Headers({  
            Authorization: `Bearer ${token}`,  
            ...options.headers,  
        });  
    }  

    return fetchUtils.fetchJson(url, options);  
};  


const Users = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteManager, setDeleteManager] = useState(null);
    const [updateManager, setUpdateManager] = useState(null);
    const [openUpdateCustomer, setOpenUpdateCustomer] = useState(false);
    const [newName, setNewName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [role, setRole] = useState('user'); 

    const UpdateButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setUpdateManager(record);
            handleUpdateOpen(true);
        }
        return <button onClick={handleClick}>Update</button>;
    };

    const handleUpdateOpen = (e) => {
        setOpenUpdateCustomer(true);
    };

    const handleUpdateClose = () => {
        setOpenUpdateCustomer(false);
    };

    const handleUpdateConfirm = async () => {  
        if (newPassword && newName && newEmail) {  
            const Update_customer_url = `${apiUrl}/update_user`;  

            // Prepare the data you want to send  
            const data = {  
                username: newName,
                email: newEmail,
                password: newPassword,
                role: role,
                user_id: updateManager._id  
            };  
    
            try {  
                // Send POST request  
                const { status } = await httpClient(Update_customer_url, {  
                    method: 'POST',
                    body: JSON.stringify(data),
                });  
    
                if (status >= 200 && status < 300) {  
                    notify(`Updated Customer: ${newName}`, { type: 'info' });  
                    setNewName('');  
                    setNewPassword(''); 
                    setNewEmail('');  
                    handleUpdateClose();  
                } else {  
                    notify(`Failed to Update customer.`, 'error');  
                } 
                refresh(); 
            } catch (error) {  
                notify(`Error updating ${newName}`, {type: 'warning'}); 
            }  
        } else {  
            notify(`Please input phone number.`, {type: 'warning'});  
        }  
    };

    const DeleteButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            setDeleteManager(record);
            setDeleteOpen(true);
        }
        return <button onClick={handleClick}>Delete</button>;
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteManager(null);
    };

    const handleDeleteConfirm = async () => {
        if (deleteManager) {
            await Manager_delete(deleteManager);
            handleDeleteClose();
        }
    };

    const Manager_delete = async (deleteManager) => {
        try {
            const delete_url = `${apiUrl}/delete_user/${deleteManager._id}`;
            await httpClient(delete_url);
            notify(`Delete Manager ${deleteManager.name}`, {type: 'info'});
            refresh();
        } catch (e) {
            notify(`Error delete ${deleteManager.name}`, {type: 'warning'});
        }
    };

    return (
        <>
            <List title='Users' {...props}>
                <Datagrid rowClick="edit" >
                    <TextField source='_id' />
                    <TextField source='name' />
                    <TextField source='email' />
                    <TextField source='role' />
                    <DeleteButton />
                    <UpdateButton />
                </Datagrid>
            </List>

             {/* Confirm delete Manager */}
             <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Manager?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Update customer */}
            <Dialog open={openUpdateCustomer} onClose={handleUpdateClose}>
                <DialogTitle>Update manager</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input name email password role
                    </DialogContentText>
                    <Form record={{ id: 123, title: 'Lorem ipsum' }}>
                        <TextInput   
                            source="Name"   
                            value={newName}   
                            onChange={(e) => setNewName(e.target?.value)}   
                            resettable   
                        />  
                        <TextInput   
                            source="Email"   
                            value={newEmail}   
                            onChange={(e) => setNewEmail(e.target?.value)}  
                            resettable   
                        />     
                        <TextInput   
                            source="Password"   
                            value={newPassword}   
                            onChange={(e) => setNewPassword(e.target?.value)}  
                            resettable   
                        />
                        <SelectInput   
                            source="role"   
                            value={role}   
                            onChange={(e) => setRole(e.target.value)}   
                            choices={[  
                                { id: 'user', name: 'User' },  
                                { id: 'admin', name: 'Admin' },  
                            ]}  
                            resettable   
                        />  
                         
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateConfirm} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default Users;
